import React from 'react';
import './assets/Socials.css';
import data from '../../../assets/resources';

class Socials extends React.Component{
    render(){
        let socials = [];
        this.props?.location?.forEach(location => {
            socials.push(
            <div className="row" key={location.id}>
                <div className="col-2 offset-6 py-5 px-3">
                    <a className="mb-4" href={'https://'+location.facebook_url} target="_blank" rel="noreferrer">
                        <img className='img-fluid' src={data.FbLogo} alt="" />
                    </a>
                </div>
                <div className="col-2 py-5 px-3">
                    <a className="mb-4" href={"https://www.instagram.com/"+location?.instagram_username} target="_blank" rel="noreferrer">
                        <img className='img-fluid' src={data.IgLogo} alt="" />
                    </a>
                </div>
            </div>
            );
        });
        return socials;
    }
}

export default Socials;