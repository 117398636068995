import React from "react";
import { NumericFormat } from 'react-number-format';
import './assets/Products.css';
//import Add from './../../assets/images/add.png';
import data from '../../../assets/resources';
import Modal from "../modal/Modal";

class Products extends React.Component {
    
    category(category) {
        let products = [];
        category.items.forEach(item => {
            var max_price = 0;
            var min_price = 0;
            var price;
            item.itemData.variations.forEach(variation => {
                if (variation.itemVariationData.priceMoney.amount > max_price)
                    max_price = variation.itemVariationData.priceMoney.amount;
                if (variation.itemVariationData.priceMoney.amount < min_price || min_price === 0)
                    min_price = variation.itemVariationData.priceMoney.amount;
            });
            if (max_price === min_price)
                price = (<p className='text-color6 item-info poppins'><NumericFormat value={max_price / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></p>);
            else
                price = (<p className='text-color6 item-info poppins'><NumericFormat value={max_price / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /> - <NumericFormat value={min_price / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} /></p>);
            products.push(
                <div className="col-md-3 col-6" key={item.itemData.name}>
                    <div className="container m-2 p-2 bg-color3 add-button" data-bs-toggle="modal" data-bs-target={"#modal"+item.id}>
                        {/*<div className='position-absolute z-n1'>
                            <img src={Add} className='img-fluid add-button' alt={'add item'}/>
                        </div>*/}
                        <div className="mx-auto" >
                            <img src={item.itemData.imageDetails[0]?.url ? item.itemData.imageDetails[0]?.url : data.defaultLogo} className='img-fluid img-thumbnail rounded' alt="" />
                        </div>
                        <p className='text-color6 item-name poppins mt-1 mb-0'><strong>{item.itemData.name}</strong></p>
                        {price}
                    </div>
                    <Modal open={this.props?.open} product={item} cart={this.props?.cart} addItemToCart={this.addItemToCart}/>
                </div>
            );
        });
        return (
            <React.Fragment key={category.categoryData.name}>
                <h3 id={category.id} className='poppins text-color6 ms-2'><strong>{category.categoryData.name}</strong></h3>
                <div className="row">
                    {products}
                </div>
            </React.Fragment>
        );
    }
    
    render() {
        let categories = [];
        this.props?.catalog?.forEach(category => categories.push(this.category(category)));
        return (<React.Fragment>{categories}</React.Fragment>);
    }

    addItemToCart = (state) => this.props?.addItemToCart(state);

}

export default Products;