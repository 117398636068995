import React from 'react';
import data from '../assets/resources';
import './assets/Footer.css';
import Addresses from './components/addresses/Addresses';
import Socials from './components/social/Socials';

const Footer = (props) => {
        return (
            <div className="bg-color1 footer container-fluid">
                <div className="row">
                    <div className="col-md-12 center" style={{ height: '0px' }}>
                        <img className="svg-footer" src={data.SvgFooter} alt="" />
                    </div>
                    <div className="col-12 d-md-none footer-img center p-5">
                        <img className="footer-image" src={data.logo} alt="" />
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="row">
                            <div className="col-12 col-md-4 p-4">
                                <a className="mb-2 text-white poppins text-decoration-none" href="/contact"><strong>Contact</strong></a>
                            </div>
                            <div className="col-12 col-md-4 p-4">
                                <a className="mb-2 text-white poppins text-decoration-none" href="/terms-and-conditions"><strong>Terms & Conditions</strong></a>
                            </div>
                            <div className="col-12 col-md-4 p-4">
                                <a className="mb-2 text-white poppins text-decoration-none" href="/privacy-policy"><strong>Privacy Policy</strong></a>
                            </div>
                        </div>
                        <Addresses addresses={props?.locations}/>
                    </div>
                    <div className="d-none d-md-block col-md-4 footer-img center p-5">
                        <a href="/"><img className="footer-image" src={data.logo} alt="" /></a>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="footer-sm-link">
                            <Socials location={props?.locations}/>
                        </div>
                        <div className="m-0 text-white right p-3 poppins">
                            <strong >2022 All rights Reserved</strong><br/><span style={{ fontWeight: '300' }}>{process.env.REACT_APP_NAME}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default Footer;