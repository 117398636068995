import React from 'react';
import './assets/Addresses.css';

class Addresses extends React.Component {
    render() {
        let addresses = [];
        this.props?.addresses?.forEach(location =>
            addresses.push(
                <div className="col-12 p-4" key={location?.address?.locality}>
                    <p className="text-white m-0 poppins">
                    {location?.address?.address_line_1}, {location?.address?.locality}, {location?.address?.administrative_district_level_1} {location?.address?.postal_code}</p>
                    <a href={"tel:" + location.phone_number} className={'text-white m-0 poppins'}>
                        <p>
                            {location.phone_number}
                        </p>
                    </a>
                </div>
            )
        );
        return <div className="row">{addresses}</div>;
    }
}
export default Addresses;