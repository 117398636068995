import React from 'react';
import './assets/Buttons.css';

class Buttons extends React.Component{
    render() {
        let locations = [];
        if(this.props?.locations?.length > 0){
            this.props?.locations.forEach(location =>
                locations.push(
                    <div className="col" key={location.address.locality}>
                        <div className="accordion-item bg-color5">
                            <h2 className="accordion-header bg-color5" id={"flush-heading"+location.id}>
                                <button className="accordion-button collapsed bg-color5 text-white poppins" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse"+location.id} aria-expanded="false" aria-controls={"flush-collapse"+location.id}>
                                    <h4><strong>{location.address.locality}</strong></h4>
                                </button>
                            </h2>
                        </div>
                    </div>
                )
            );
        }
        return (
            <div className="px-5">
                <div className="row px-5">
                    {locations}
                    <div className="col"></div>
                </div>
            </div>
        );
    }
}

export default Buttons;